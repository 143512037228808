import { Box, Heading } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Title } from "../components/title"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const ContestRules: FunctionComponent<{ data: any }> = ({ data }) => {
  const contestRules = data.prismicContestrules && data.prismicContestrules.data

  if (!contestRules) {
    return <></>
  }

  return (
    <Layout>
      <Container>
        <SEO title={contestRules.title?.text} />
        <Title text={contestRules.title?.text} />

        <Box
          w="100%"
          className="html"
          dangerouslySetInnerHTML={{ __html: contestRules.content.html }}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ContestRules {
    prismicContestrules {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
  }
`

export default ContestRules
